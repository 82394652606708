import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';

const ResourceSectionByIds = ({relatedResources}) => {
    const [resources, setResources] = useState([]);

    const fetchData = async () => {
        const serviceCatRef = collection(db, "Resources");
        const resourcesData = [];
        console.log(relatedResources);
        const resourceIds = relatedResources.map(resource => resource.value);

        for (const id of resourceIds) {
            const docRef = doc(serviceCatRef, id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                resourcesData.push({
                    id: docSnap.id,
                    ...docSnap.data(),
                });
            }
        }

        setResources(resourcesData);
    };

    useEffect(() => {
        fetchData();
    },[relatedResources]);

    return (
        <>
            {resources.length > 0 ?
                <h1>Resources</h1> : null
            }
            <div className="section-full content-inner">
                <div className="container">
                    <div className="row">
                        {resources.map(service => (
                            <div key={service.id} className="col-lg-4 col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                <Link to={`/resources/${service.url}`}>
                                    <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                        <div className="dlab-media dlab-img-effect zoom">
                                            <img src={service.imageUrl} alt="" />
                                        </div>
                                        <div className="icon-content">
                                            <h2 className="dlab-tilte">{service.title}</h2>
                                            {/* <Link to={`/resources-details/${service.id}`} className="link-btn">more</Link> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResourceSectionByIds;

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export function GetWebLocation() {
  let { weblocation } = useParams();
  const [webLocationResult, setWebLocationResult] = useState(weblocation);

  useEffect(() => {
    if (!webLocationResult) {
      setWebLocationResult(process.env.REACT_APP_DEFAULT_WEB_LOCATION);
    }
  }, [weblocation]);

  return webLocationResult;
}
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, where, getDocs } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const ServicesOfCategoriesSection = ({ serviceCategoryRef }) => {
    const weblocation = GetWebLocation();
    const [services, setServices] = useState([]);
    
    const fetchData = () => {
        const serviceRef = collection(db, "Services");
        const q = query(
            serviceRef, 
            where("serviceCategoryRef", "==", serviceCategoryRef),
            where("published", "==", true) 
        );
        
        onSnapshot(q, (snapshot) => {
            const servicesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            const filteredServices = servicesData.filter(service =>
                service.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );
    
            setServices(filteredServices);
    
        }, []);
    };
    

    useEffect(() => {
        fetchData();
    }, [serviceCategoryRef, weblocation]);

    return (
        <>
            {services.length > 0 ?
                <h1>Services</h1> : null
            }
            <div className="section-full content-inner">
                <div className="container">
                    <div className="row">
                        {services.map(service => (
                            <div key={service.id} className="col-lg-4 col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                <Link to={"/" + weblocation + `/service/${service.url}`}>
                                    <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                        <div className="dlab-media dlab-img-effect zoom">
                                            <img src={service.imageUrl} alt="" />
                                        </div>
                                        <div className="icon-content">
                                            <h2 className="dlab-tilte">{service.title}</h2>
                                            {/* <Link to={`/services-details/${service.id}`} className="link-btn">more</Link> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesOfCategoriesSection;

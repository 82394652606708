import React from "react";
const bnr4 = require('./../../images/banner/bnr-resouces.jpg');

const ResourceHubHeader = () => {
    return (
        <div className="dlab-bnr-inr overlay-black-middle text-center bg-pt"
            style={{
                backgroundImage: "url(" + bnr4 + ")",
                height: '200px'
            }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry" style={{ height: '200px' }}>
                    <h1 className="text-white h1Hub">Resource Center</h1>
                </div>
            </div>
        </div>
    )
}

export default ResourceHubHeader;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import HeaderNavBar from './headerNavBar';
import HeaderWebLocations from './headerLocations';
import HeaderLocationCaption from './headerLocationCaption';



class Header extends Component {

    state = {
        locationModal: false
    }

    toggleLocationModal = () => {
        this.setState(prevState => ({ locationModal: !prevState.locationModal }));
    }

    hideLocationModal = () => {
        this.setState({ locationModal: false });
    }

    componentDidMount() {

        var searchBtn = document.getElementById("quik-search-btn")
        var searchPopup = document.querySelector(".dlab-quik-search")
        var closeBtn = document.getElementById("quik-search-remove")

        searchBtn.addEventListener('click', function () {
            searchPopup.style.display = "block"
            searchPopup.classList.add("On")
        })

        closeBtn.addEventListener('click', function () {
            searchPopup.style.display = "none"
            searchPopup.classList.remove("On")
        })

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');

            if (active) {
                current.classList.remove('open')
                //console.log("active")
            } else {
                current.classList.add('open');
                //console.log("close")
            }
        }

    }
    render() {

        return (
            <>
                <header className="site-header mo-left header">
                    <div className="top-bar">
                        <div className="container">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="dlab-topbar-left">
                                    <ul>
                                        {/* <li><a id="btnGlobal" href='#'>Global</a></li> */}
                                        <li>
                                            <a id="btnGlobal" className="ms-1" href='#' onClick={this.toggleLocationModal}>
                                                <HeaderLocationCaption />
                                            </a>
                                        </li>

                                        <li><Link to={'/#'} className="ms-1">Offices & Labs</Link></li>
                                        <li><a  href='https://clients.citadel-engineering.com/' itemScope  target='_blank' className="ms-1">Client Area</a></li>

                                    </ul>
                                </div>
                                <div className="dlab-topbar-right">
                                    <Link to={'/#'} className="site-button radius-no">Contact</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Sticky innerZ={999} enabled={true}>
                        <div className="sticky-header main-bar-wraper navbar-expand-lg">
                            <div className="main-bar clearfix ">
                                <div className="container clearfix">

                                    <div className="logo-header mostion">
                                        <Link to={'/'}><img src={require('../../images/logo.png')} alt="" /></Link>
                                    </div>

                                    <button className="navbar-toggler navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>

                                    <div className="extra-nav">
                                        <div className="extra-cell">
                                            <button id="quik-search-btn" type="button" className="site-button-link"><i className="la la-search"></i></button>
                                        </div>
                                    </div>

                                    <div className="dlab-quik-search ">
                                        <form action="#">
                                            <input name="search" value="" type="text" className="form-control" placeholder="Type to search" />
                                            <span id="quik-search-remove"><i className="ti-close"></i></span>
                                        </form>
                                    </div>

                                    {this.state.locationModal ?
                                        <div class="dlab-location">
                                            <div className='listContainer'>
                                                <span id="quik-location-remove" onClick={this.hideLocationModal}><i className="ti-close"></i></span>
                                                <HeaderWebLocations />
                                            </div>
                                        </div>

                                        : null
                                    }

                                    <div class={`header-nav navbar-collapse collapse justify-content-end`} id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to={'/'}><img src={require('../../images/logo.png')} alt="" /></Link>
                                        </div>
                                        <HeaderNavBar></HeaderNavBar>

                                        <div className="dlab-social-icon">
                                            <ul>
                                                <li><Link className="site-button circle fa fa-facebook" to={'/#'}></Link></li>
                                                <li><Link className="site-button  circle fa fa-twitter" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-linkedin" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-instagram" to={'/#'}></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>

                </header>

            </>
        )
    }
}
export default Header;



import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import { collection, query, where, getDocs, orderBy, startAfter, limit } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import ResourceItem from '../element/resourceItem';
import ResourceHubFilter from '../element/resourceHubFilter';
import ResourceHubHeader from '../element/resourceHubHeader';

const ResourceHub = () => {


    const [entitiesList, setEntitiesList] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const ITEMS_PER_PAGE = 10;

    const fetchData = async () => {
        try {
            let q = query(collection(db, 'Resources'),
                where('published', '==', true),
                orderBy("createdAt", 'desc'),
                limit(ITEMS_PER_PAGE)
            );

            if (lastVisible) {
                q = query(collection(db, 'Resources'),
                    where('published', '==', true),
                    orderBy("createdAt", 'desc'),
                    startAfter(lastVisible),
                    limit(ITEMS_PER_PAGE)
                );
            }

            const querySnapshot = await getDocs(q);

            let newEntities = entitiesList;
            querySnapshot.forEach((doc) => {
                newEntities.push({ id: doc.id, ...doc.data() });
            });
            setEntitiesList(newEntities);

            if (querySnapshot.docs.length > 0) {
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            // If we fetched less than the required items per page, it's safe to assume we've reached the end.
            if (querySnapshot.docs.length < ITEMS_PER_PAGE) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error getting documents: ', error);
        }
    }



    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <ResourceHubHeader />

                <div className="section-full bg-white content-inner">
                    <div className="container">

                        <div className="row">
                            <ResourceHubFilter />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-content box-sort-in button-example p-b0">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            {
                                                entitiesList.map((entity) =>
                                                    <ResourceItem entity={entity} ></ResourceItem>
                                                )
                                            }

                                            <div className="pagination-bx clearfix text-center">
                                                {hasMore && (
                                                    <div className="dlab-post-readmore">
                                                        <a
                                                            onClick={() => fetchData()}
                                                            className="site-button">Load More Items ...
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ResourceHub;
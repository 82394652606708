import * as React from "react";
import Select from "react-select";

const options = [
  { value: 'last_three_months', label: 'Last Three Months' },
  { value: 'last_six_months', label: 'Last Six Months' },
];

const DateRangeLkp = ({ onChange, isDisabled }) => {

  const [selectedOption, setSelectedOption] = React.useState();

  React.useEffect(() => {
    if (options && options.length > 0) {
      const option = findOptionByValue('last_three_months'); // set default value
      if (option) setSelectedOption(option);
    }
  }, []);

  const selectedOptionHandler = (selectedValue) => {
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  }

  const findOptionByValue = (value) => {
    return options.find(option => option.value === value);
  };

  return (
    <>
      <div className="form-group">
        <div>
          {/* <span className="input-group-addon">Date</span> */}
          <Select
            placeholder="All Dates"
            value={selectedOption}
            onChange={selectedOptionHandler}
            options={options}
            controlShouldRenderValue
            isClearable
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </>
  )
}

export default DateRangeLkp;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const ServiceCategoriesSection = () => {
    const [services, setServices] = useState([]);
    const weblocation = GetWebLocation();

    const fetchData = () => {

        const serviceCatRef = collection(db, "ServiceCats");
        const q = query(serviceCatRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const filteredServices = articlesDate
            .filter(service =>
                service.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );

            setServices(filteredServices);

        }, []);
    };

    useEffect(() => {
        fetchData();
      }, [weblocation]);



        return (
            <>
                <div className="section-full bg-gray content-inner">
                    <div className="container">
                        <div className="row">
                            {services.map(service => (
                                <div key={service.id} className="col-lg-4 col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <Link to={`/services/${service.url}`}>
                                        <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                            <div className="dlab-media dlab-img-effect zoom">
                                                <img src={service.imageUrl} alt="" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">{service.title}</h2>
                                                {/* <Link to={`/services-details/${service.id}`} className="link-btn">more</Link> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }

export default ServiceCategoriesSection;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const HeaderLocationCaption = () => {
    const weblocation = GetWebLocation();

    return (
            <>
                Location ({weblocation})
            </>
    )
}

export default HeaderLocationCaption;
import React from "react";
import ServiceCategoriesLkp from '../Lookups/ServiceCategories/ServiceCategoriesLkp';
import WebLocationLkp from '../Lookups/WebLocationLkp/WebLocationLkp';
import ResourceCategoriesLkp from '../Lookups/ResourceCategories/ResourceCategories';
import DateRangeLkp from '../Lookups/DateRangeLkp/DateRangeLkp';

const ResourceHubFilter = () => {

    return (
        <>
            <div className="col-lg-12 col-md-12 m-b30">
                <form className="inquiry-form wow box-shadow bg-white fadeInUp" data-wow-delay="0.2s">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <WebLocationLkp ></WebLocationLkp>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <DateRangeLkp></DateRangeLkp>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <ResourceCategoriesLkp></ResourceCategoriesLkp>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <ServiceCategoriesLkp></ServiceCategoriesLkp>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <div>
                                    <input name="title" type="text" required className="form-control" placeholder="Search Title" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ResourceHubFilter;
import React from 'react';
import { Link } from 'react-router-dom';
import { GetWebLocation } from '../../utils/urlHelper';

const ResourceItem = ({ entity }) => {

    const weblocation = GetWebLocation();

    return (
        <div key={entity.id} className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm">
            <div className="dlab-post-media dlab-img-effect zoom-slow">
                <Link to={`/${weblocation}/resources/${entity.url}`}><img src={entity.imageUrl} alt="" /></Link>
            </div>
            <div className="dlab-post-info">
                <div className="dlab-post-title">
                    <h4 className="post-title"><Link to={`/${weblocation}/resources/${entity.url}`}>{entity.title}</Link></h4>
                </div>
                <div className="dlab-post-text">
                    <p className="p-r15">{entity.description}</p>
                </div>
                <div className="dlab-post-readmore">
                    <Link to={`/${weblocation}/resources/${entity.url}`} title="READ MORE" rel="bookmark" className="site-button">READ MORE
                        <i className="ti-arrow-right"></i>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ResourceItem;

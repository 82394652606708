import * as React from "react";
import Select from "react-select";
// import { InputLabel } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { GetWebLocation } from "../../../utils/urlHelper";

const WebLocationLkp = ({ onChange, companyRef, isDisabled }) => {

    const [data, setData] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState();
    const weblocation = GetWebLocation();

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        if (weblocation && data.length > 0) {
            const option = findOptionByTitle(weblocation.toUpperCase());
            if (option) setSelectedOption(option);
        }
    }, [weblocation, data]);

    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "WebLocations"));

            const list = querySnapshot.docs.map(doc => ({
                value: doc.id,
                label: `${doc.data().description + ' (' + doc.data().title + ') ' }`,
                title: doc.data().title,
            }));

            setData(list);
        } catch (error) {
            console.error(error);
        }
    }

    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue);
        onChange(selectedValue);
    }

    const findOptionByTitle = (title) => {
        return data.find(option => option.title === title);
    };

    const findOptionByValue = (value) => {
        return data.find(option => option.value === value);
    };

    return (
        <>
            <div className="form-group">
                <div>
                    {/* <span className="input-group-addon">Location</span> */}
                    <Select
                        // className="form-control"
                        placeholder="All Locations"
                        value={selectedOption}
                        onChange={selectedOptionHandler}
                        options={data}
                        controlShouldRenderValue
                        isClearable
                        isDisabled={isDisabled}
                    />
                </div>
            </div>

        </>
    )
}

export default WebLocationLkp;
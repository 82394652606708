import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, where } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const HeaderNavBar = () => {
    const weblocation = GetWebLocation();


    const [serviceCats, setServiceCats] = useState([]);
    const [services, setServices] = useState([]);
    const [companyPageCats, setCompanyPageCats] = useState([]);
    const [companyPages, setCompanyPages] = useState([]);

    const fetchServiceCats = () => {
        const serviceCatRef = collection(db, "ServiceCats");
        const q = query(serviceCatRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const filteredServices = articlesDate.filter(service =>
                service.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );

            setServiceCats(filteredServices);

        }, []);
    }

    const fetchServices = () => {
        const serviceRef = collection(db, "Services");
        const q = query(
            serviceRef,
            where("published", "==", true)
        );

        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const filteredServices = articlesDate.filter(service =>
                service.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );

            setServices(filteredServices);
        }, []);
    }

    const fetchCompanyPageCats = () => {
        const companyPageCatRef = collection(db, "CompanyPageCats");
        const q = query(companyPageCatRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const filteredServices = articlesDate.filter(service =>
                service.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );

            setCompanyPageCats(filteredServices);


        }, []);
    }

    const fetchCompanyPages = () => {
        const companyPageRef = collection(db, "CompanyPages");
        const q = query(
            companyPageRef,
            where("published", "==", true)
        );

        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const filteredCompanyPages = articlesDate.filter(companyPage =>
                companyPage.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );

            setCompanyPages(filteredCompanyPages);
        }, []);
    }

    const fetchData = () => {
        fetchServiceCats();
        fetchServices();
        fetchCompanyPageCats();
        fetchCompanyPages();
    };

    useEffect(() => {
        fetchData();
    }, [weblocation]);

    return (
        <ul className="nav navbar-nav">
            <li className="has-mega-menu"> <Link to={'#'}>Industries & Services<i className="fa fa-chevron-down"></i></Link>
                <ul className="mega-menu">
                    {serviceCats.map(serviceCat => (
                        <li key={serviceCat.id}>
                            <Link to={"/" + weblocation + `/services/` + serviceCat.url}>{serviceCat.title}</Link>
                            <ul>
                                {services.filter(service => service.serviceCategoryRef === serviceCat.id).map(service => (
                                    <li key={service.id}><Link to={"/" + weblocation + `/service/${service.url}`}>{service.title}</Link></li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </li>


            <li className="has-mega-menu"> <Link to={'#'}>Our Company<i className="fa fa-chevron-down"></i></Link>
                <ul className="mega-menu">
                    {companyPageCats.map(companyPageCat => (
                        <li key={companyPageCat.id}>
                            <Link to={"/" + weblocation + `/pages/` + companyPageCat.url}>{companyPageCat.title}</Link>
                            <ul>
                                {companyPages.filter(companyPage => companyPage.companyPageCategoryRef === companyPageCat.id).map(companyPage => (
                                    <li key={companyPage.id}><Link to={"/" + weblocation + `/page/${companyPage.url}`}>{companyPage.title}</Link></li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </li>


            <li className="has-mega-menu">
                <Link to={'#'}>Careers</Link>
            </li>
            <li className="has-mega-menu">
                <Link to={"/" + weblocation + `/resources/`}>News & Resources</Link>
            </li>
        </ul>

    )
}

export default HeaderNavBar;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, where, getDocs } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const CompanyPagesOfCategoriesSection = ({ companyPageCategoryRef }) => {
    const weblocation = GetWebLocation();
    const [companyPages, setCompanyPages] = useState([]);
    
    const fetchData = () => {
        const companyPageRef = collection(db, "CompanyPages");
        const q = query(
            companyPageRef, 
            where("companyPageCategoryRef", "==", companyPageCategoryRef),
            where("published", "==", true) 
        );
        
        onSnapshot(q, (snapshot) => {
            const companyPagesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            const filteredCompanyPages = companyPagesData.filter(companyPage =>
                companyPage.relatedWebLocations.some(location => location.label === weblocation.toUpperCase())
            );
    
            setCompanyPages(filteredCompanyPages);
    
        }, []);
    };
    

    useEffect(() => {
        fetchData();
    }, [companyPageCategoryRef, weblocation]);

    return (
        <>
            {companyPages.length > 0 ?
                <h1>CompanyPages</h1> : null
            }
            <div className="section-full content-inner">
                <div className="container">
                    <div className="row">
                        {companyPages.map(companyPage => (
                            <div key={companyPage.id} className="col-lg-4 col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                <Link to={"/" + weblocation + `/page/${companyPage.url}`}>
                                    <div className="icon-bx-wraper companyPage-box style5 shadow fly-box">
                                        <div className="dlab-media dlab-img-effect zoom">
                                            <img src={companyPage.imageUrl} alt="" />
                                        </div>
                                        <div className="icon-content">
                                            <h2 className="dlab-tilte">{companyPage.title}</h2>
                                            {/* <Link to={`/companyPages-details/${companyPage.id}`} className="link-btn">more</Link> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyPagesOfCategoriesSection;

import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../../firebaseConfig";
import ResourceSectionByIds from '../element/resourceSectionByIds';

const bg = require('../../images/banner/bnr4.jpg')

const Service = () => {

    const [entity, setEntity] = useState([]);
    let { weblocation, serviceUrl } = useParams();

    useEffect(() => {

        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(query(collection(db, 'Services'), where('url', '==', serviceUrl)));
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    setEntity(doc.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error('Error getting documents: ', error);
            }
        }
        fetchData();
    }, [serviceUrl]);

    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + entity.imageUrl + ")" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <div>
                                <h1 className="text-white">{entity.title}</h1>
                                <h3 className="text-white" >{entity.description}</h3>
                            </div>
                            {/* <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Portfolio</li>
                                    <li>Portfolio Detail</li>
                                </ul>
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 m-b30">
                                    <div dangerouslySetInnerHTML={{ __html: entity.page }} />

                                    {/* <h2 className="text-black font-weight-600 m-b15">Project Information</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an printer took a galley of type and scrambled it to make.</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an printer took a galley of type and scrambled it to make.</p> */}
                                </div>
                                {/* 
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 m-b30">
                                            <img alt="" src={require("../../images/about/pic3.jpg")} />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                            <img alt="" src={require("../../images/our-services/pic2.jpg")} />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                            <img alt="" src={require("../../images/our-services/pic3.jpg")} />
                                        </div>
                                        <div className="col-lg-12 m-b30">
                                            <img alt="" src={require("../../images/about/about2.jpg")} />
                                        </div>
                                    </div>
                                </div>
                                */}

                                {/* todo child services

                                todo news and resources

                                todo footer */}
                                <ResourceSectionByIds relatedResources={entity.relatedResources}></ResourceSectionByIds>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )

}
export default Service;
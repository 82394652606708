import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { GetWebLocation } from '../../utils/urlHelper';

const HeaderWebLocations = () => {
    const [webLocations, setWebLocations] = useState([]);
    const weblocation  = GetWebLocation();

    const fetchData = () => {

        const serviceCatRef = collection(db, "WebLocations");
        const q = query(serviceCatRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (snapshot) => {
            const articlesDate = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setWebLocations(articlesDate);
        }, []);
    };

    useEffect(() => {
        fetchData();
    }, []);



    return (
            <ul className='locationUl'>
                {/* <li><a href='#' style={{fontWeight:'bold'}}>GLOBAL</a></li> */}
                {webLocations.map(wl=>(
                    <li> {( wl.title.toLowerCase() == (weblocation)) ? 
                        <a href={`/` + wl.url + '/' } style={{fontWeight:'bold'}}>
                            {wl.description} </a> : 
                        <a href={`/` + wl.url + '/' } >
                            {wl.description}
                        </a>
                    
                    }
                    </li>    
                ))}
            </ul>
    )
}

export default HeaderWebLocations;
